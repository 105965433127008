import { useEffect } from "react";
import Slider from "react-slick";
import Image1 from "../../assets/images/ai-1.png";
import Image2 from "../../assets/images/ai-2.png";
import Image3 from "../../assets/images/ai-3.png";
import Image4 from "../../assets/images/ai-4.png";
import { Helmet } from "react-helmet-async";
import { useLogEvent } from "../../analytics";
import { PrimaryButton } from "../../components/PrimaryButton";
import { useAIPhotosStore } from "../../stores/aiphotos";
import { useAIPhotosResults } from "./useAIPhotosResults";

var settings = {
  dots: true,
  infinite: true,
  autoplay: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
};

const images = [Image1, Image2, Image3, Image4];

type Props = {
  onNextPress: () => void;
};

export const AIPhotosLanding = ({ onNextPress }: Props) => {
  const { aiPhotosEmail } = useAIPhotosStore();
  const { setAIPhotosWizardComplete } = useAIPhotosStore();
  const { fetchAIPhotoBatches, loadingBatches } = useAIPhotosResults();

  const checkBatches = async () => {
    if (!aiPhotosEmail) {
      setAIPhotosWizardComplete(false);
    } else {
      const batches = await fetchAIPhotoBatches();
      setAIPhotosWizardComplete(batches.length > 0);
    }
  }

  useEffect(() => {
    checkBatches()
  }, []);

  useLogEvent("start", "photos");

  return (
    <div className="mx-auto max-w-xl flex flex-col justify-center">
      <div className="flex flex-col">
        <Helmet>
          <meta name="title" content="AI Enhanced Dating Photos" />
          <meta
            name="description"
            content="Get studio quality AI photos for your dating profile at 1/10th the cost."
          />
        </Helmet>
        <div className="flex items-center text-center justify-center my-4 px-10 mt-6">
          <h1 className="text-2xl font-bold mx-4">
            Stand out with
            <br />
            AI-Enhanced Photos
          </h1>
        </div>
        <div className="flex items-center justify-center text-center">
          <p className="text-sm font-semibold">
            💕 Double your swipes with more charming you
          </p>
        </div>
        <div className="flex items-center justify-center text-center">
          <p className="text-sm text-[#999999]">
            Look your best while staying true to who you are.
          </p>
        </div>
        <div className="items-center text-center my-8 mx-12 max-w-full">
          <Slider {...settings}>
            {images.map((image) => {
              return (
                <div className="items-center">
                  <img
                    className="items-center"
                    alt="profile photo improved using AI"
                    src={image}
                  />
                </div>
              );
            })}
          </Slider>
        </div>
        <div className="mt-4 mx-4">
          <h4 className="font-semibold text-sm md:text-md mb-2">
            What's included:
          </h4>
          <ul className="list-none space-y-1">
            <li className="flex items-center text-sm md:text-md">
              ✅ Show your best self
            </li>
            <li className="flex items-center text-sm md:text-md">
              ✅ 90 photos in 15 different backgrounds & styles
            </li>
            <li className="flex items-center text-sm md:text-md">
              ✅ Up to 44% more matches on average
            </li>
          </ul>
          <h4 className="font-semibold text-sm md:text-md mt-4 mb-2">
            What we won't do:
          </h4>
          <ul className="list-none space-y-1">
            <li className="flex items-center text-sm md:text-md">
              ❌ Create fake-looking photos
            </li>
            <li className="flex items-center text-sm md:text-md">
              ❌ Make you taller or give you a dog
            </li>
          </ul>
        </div>
        <PrimaryButton
          loadingTitle={loadingBatches ? "Loading..." : undefined}
          disabled={loadingBatches}
          containerClass="m-4 mt-8 p-4"
          onClick={onNextPress}
          title="Enhance my photos"
        />
      </div>
    </div>
  );
};
