import { usePostHog } from 'posthog-js/react'

type Props = {
  title: string;
  popular?: boolean;
  price: number;
  salePercentage?: number;
  additionalDescription?: string;
  onPress?: VoidFunction
};

export const SubscriptionPlan = ({
  title,
  popular = false,
  price,
  salePercentage = undefined,
  additionalDescription = undefined,
  onPress = undefined
}: Props) => {
  // const postHog = usePostHog()
  // const showDisclaimer = postHog?.getFeatureFlag('show-moneyback-disclaimer') === 'treatment'

  const discountedPrice = salePercentage ? Math.round(price - price * (salePercentage / 100)) : undefined;
  return (
    <div className="bg-white p-3 rounded-lg shadow-inner border-2 border-black flex flex-col h-full">
      <div className="flex flex-col flex-1 justify-between">
        <div className="flex-1">
          <div className="flex justify-between items-center">
            <p className="font-medium text-sm text-black">
              {title}
            </p>
            <p className={`font-bold ${popular ? "text-brand-primary" : "text-[#999999]"} text-[10px]`}>
              {popular ? "Most Popular" : "Flexible Plan"}
            </p>
          </div>
          <div className="mt-0">
            <span className={`${discountedPrice ? "text-sm text-[#999999] line-through" : "text-base text-brand-primary"} font-semibold`}>
              {`$${price}${discountedPrice ? "" : "/mo"}`}
            </span>
            {discountedPrice ? <span className="text-base font-semibold text-brand-primary ml-1">{`$${discountedPrice}/mo`}</span> : null}
            {additionalDescription && <p className="text-xs text-[#999999]">{additionalDescription}</p>}
          </div>
        </div>
        <div className="pt-2 mt-auto">
          <button className="w-full py-1 bg-brand-primary text-white rounded text-sm" onClick={onPress}>
            Get Started
          </button>
          {/* {showDisclaimer &&  */}
          <p className="text-xs text-gray-500 text-center mt-2">7-day money back guarantee</p>
        </div>
      </div>
    </div>
  );
}

export default SubscriptionPlan;