import toast from "react-hot-toast";
import { QueryClient, QueryClientProvider } from "react-query";
import * as Sentry from "@sentry/react";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import { ProfileWriter } from "./pages/ProfileWriter";
import { AIPhotos } from "./pages/aiphotos/AIPhotos";
import { ChatAssistant } from "./pages/ChatAssistant";
import { ProfileReviewer } from "./pages/profile-review/ProfileReviewer";
import { ProfileReviewerDemo } from "./pages/review-demo/ProfileReviewerDemo";
import { useEffect } from "react";
import { auth } from "./firebase";
import { checkIsUserCreator, checkUserSubscription, createOrGetAuthUser } from "./queries";
import { useAuthStore } from "./stores/auth";
import { useUIStore } from "./stores/ui";
import Page from "./pages/Page";
import { Onboarding } from "./pages/Onboarding";
import { User, signOut } from "firebase/auth";
import { AuthState } from "./constants/auth";
import { useABTest } from './components/ab-testing/useABTest'
import { useReferralQueryParams } from "./pages/referral/useReferralQueryParams"
import { useCampaignQueryParams } from "./pages/useCampaignQueryParams"
import { AIPhotoReviewWizard } from "./pages/photo-review/AIPhotoReviewWizard";
import { useWizardStore } from "./stores/wizard";
import { useUserStore } from "./stores/user";
import { UserSettings } from "./pages/UserSettings";
import { ProfileReviewerWelcome } from "./pages/welcome/ProfileReviewerWelcome";
import { AccountDeletionRequest } from "./pages/AccountDeletionRequest";
import NotFound from './pages/NotFound';

const queryClient = new QueryClient();


// Init Sentry if we have an environment var for it
if (import.meta.env.VITE_SENTRY_DSN) {
  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.browserProfilingIntegration(),
      Sentry.replayIntegration(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    tracePropagationTargets: [
      "localhost",
      /^https:\/\/yourmove-api-production\.up\.railway\.app/,
      /^https:\/\/web\.yourmove\.ai\/api/,
      /^https:\/\/yourmove-production-jg7rg\.ondigitalocean\.app\/api/,
    ],
  });
}

function App() {
  const {
    setIsSubscribed,
    setAuthState
  } = useAuthStore();
  const {
    setStopScroll,
    setHideBottomNav,
    setHideTopBar,
  } = useUIStore();

  const { setIsCreator } = useUserStore()

  const { email, setEmail } = useWizardStore()

  useReferralQueryParams();
  useCampaignQueryParams();

  const location = useLocation();

  useABTest()

  // For hiding the bottom and side nav
  const hiddenBottomNavPages = ["/start", "/review-demo"];
  const hiddenTopNavPages = ["/start", "/review-demo"];

  useEffect(() => {
    setHideBottomNav(hiddenBottomNavPages.includes(location.pathname));
    setHideTopBar(hiddenTopNavPages.includes(location.pathname));

    // When the URL changes, set the default stopScroll back to false
    setStopScroll(false);
  }, [location]);


  const checkForSubscription = async () => {
    const userEmail = auth.currentUser?.email ?? email
    if (userEmail) {
      const response = await checkUserSubscription(userEmail)
      setIsSubscribed(response.data.isSubscribed)
    }
  };

  const checkIfUserIsCreator = async () => {
    const userEmail = auth.currentUser?.email ?? email
    if (userEmail) {
      const response = await checkIsUserCreator(userEmail)
      setIsCreator(response.data.isCreator)
    }
  }

  // When the auth state changes, check if the user isSubscribed
  useEffect(() => {
    const createUser = async (user: User) => {
      const { uid, email = "" } = user || {};
      try {
        // create an account here using the id returned from the auth so we can map email to id.
        // fix for apple id issue
        await createOrGetAuthUser(uid, email as string);
        setEmail(email as string)
      } catch (error) {
        console.error("Error creating or retrieving user:", error);
        // Handle the error as needed, e.g., show a notification or log the error
        const errorMessage =
          "An error occured while try to fetch the user. Please try signing in again. If the problem persists, please contact support@yourmove.ai for assistance.";
        toast.error(errorMessage);
        signOut(auth);
      }
    };

    const unsubscribe = auth.onAuthStateChanged(function (user) {
      // only check for the subscription if there is a user and we haven't checked before
      // when we sign out, we can set the hasCheckedForSubscription to false so we will check on signIn again
      // we can also set hasCheckedForSubscription to false when we buy a subscription
      if (user) {
        createUser(user);
        setAuthState(AuthState.Authenticated)
        checkIfUserIsCreator()
      } else {
        setAuthState(AuthState.NotAuthenticated)
        setIsCreator(false)
      }
      checkForSubscription();
    });
    return () => unsubscribe();
  }, []);

  // TODO: CHECK FOR SUBSCRIPTION HERE::
  useEffect(() => {
    if (!auth.currentUser && email && email.length > 0 && window.tolt_referral) {
      window.tolt.signup(email)
    }
  }, [email])

  return (
    <QueryClientProvider client={queryClient}>
      <Routes>
        <Route
          path="/"
          element={
            localStorage.getItem("hasOnboarded") ? (
              <Navigate to="/chat-assistant" />
            ) : (
              <Navigate to="/start" />
            )
          }
        />
        <Route
          path="/start"
          element={
            <Page title="Start">
              <Onboarding />
            </Page>
          }
        />
        <Route
          path="/chat-assistant"
          element={
            <Page title="Chat Assistant">
              <ChatAssistant />
            </Page>
          }
        />
        <Route
          path="/profile-writer"
          element={
            <Page title="Profile Writer">
              <ProfileWriter />
            </Page>
          }
        />
        <Route
          path="/profile-review"
          element={
            <Page title="Profile Review">
              <ProfileReviewer />
            </Page>
          }
        />
        <Route
          path="/ai-photo"
          element={
            <Page title="AI Enhanced Photos">
              <AIPhotos />
            </Page>
          }
        />
        <Route
          path="/ai-photo-review"
          element={
            <Page title="AI Photo Review">
              <AIPhotoReviewWizard />
            </Page>
          }
        />
        <Route
          path="/user-settings"
          element={
            <Page title="User Settings">
              <UserSettings />
            </Page>
          }
        />
        <Route
          path="/welcome"
          element={
            <Page title="Welcome">
              <ProfileReviewerWelcome />
            </Page>
          }
        />
        <Route
          path="/review-demo"
          element={
            <Page title="Review Demo">
              <ProfileReviewerDemo />
            </Page>
          }
        />
        <Route
          path="/request-delete"
          element={
            <Page title="Request Account Deletion">
              <AccountDeletionRequest />
            </Page>
          }
        />
        {/* <Route
          path="/user-referrals"
          element={
            <Page title="YourMove - Referrals">
              <UserReferrals />
            </Page>
          }
        /> */}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </QueryClientProvider>
  );
}

export default App;
