import { useState } from "react";
import { useAIPhotosStore } from "../../stores/aiphotos";
import { useAuthStore } from "../../stores/auth";
import { CrossIconTransparent } from "../../components/CrossIcon";
import { PlusIcon } from "@heroicons/react/20/solid";
import { useAIPhotosUpload } from "./useAIPhotosUpload";
import { AIPhotosPlan } from "../../constants/payments";
import { AIPHOTOS_BASIC_PRICE, AIPHOTOS_PLUS_PRICE_SUBSCRIBED, AIPHOTOS_BASIC_PRICE_SUBSCRIBED, AIPHOTOS_PLUS_PRICE } from "../premium/paymentsConfig";

type Props = {
  onContinue: (selectedPlan: AIPhotosPlan) => void;
};

export const PreviewPhotosStep = ({ onContinue }: Props) => {
  const { filesToUpload, removeFile } = useAIPhotosStore();
  const { isSubscribed } = useAuthStore();
  const [selectedPackage, setSelectedPackage] = useState<AIPhotosPlan>(AIPhotosPlan.Basic);

  const handlePackageSelect = (packageName: AIPhotosPlan) => {
    setSelectedPackage(packageName);
  };

  const handleContinueClick = () => {
    onContinue(selectedPackage);
  };

  const { getRootProps, getInputProps } = useAIPhotosUpload(undefined, false);

  return (
    <div className="flex flex-col w-full max-w-xl h-auto">
      <div className="w-full bg-white border-2 border-black rounded-xl shadow-lg">
        <h1 className="text-center text-green-600 p-3 rounded-t-xl bg-green-100 text-xs font-semibold px-6">
          Congratulations! Your photos are ready to be processed
        </h1>
        <div
          className="mb-4 mt-4 overflow-x-auto px-4"
          style={{ scrollbarWidth: "none", msOverflowStyle: "none" }}
        >
          <div className="flex space-x-4 after:w-4 after:flex-shrink-0">
            {filesToUpload.map((file, index) => (
              <div
                key={index}
                className="w-[120px] h-[120px] flex-shrink-0 relative"
              >
                <img
                  src={URL.createObjectURL(file)}
                  alt={`Uploaded Preview ${index + 1}`}
                  className="w-full h-full object-cover rounded-lg"
                />
                <button
                  onClick={() => removeFile(index)}
                  className="absolute top-0 right-0 p-1 rounded-full shadow-md"
                >
                  <CrossIconTransparent />
                </button>
              </div>
            ))}
            <div
              {...getRootProps()}
              className="w-[120px] h-[120px] flex-shrink-0 border border-brand-primary rounded-lg flex items-center justify-center cursor-pointer"
            >
              <input {...getInputProps()} />
              <PlusIcon
                style={{ height: 40 }}
                className="text-brand-primary"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="mt-4">
        <h2 className="text-xl font-bold mb-4">Choose your package</h2>
        <div className="flex justify-between mb-4">
          <div
            onClick={() => handlePackageSelect(AIPhotosPlan.Basic)}
            className={`border-2 bg-white ${selectedPackage === AIPhotosPlan.Basic
              ? "border-brand-primary"
              : "border-transparent"
              } rounded-2xl p-4 flex-1 mr-1 cursor-pointer`}
          >
            <div className="flex justify-between items-center mb-1">
              <h3 className="font-semibold">Basic</h3>
              <p className="font-semibold">
                {isSubscribed ? `$${AIPHOTOS_BASIC_PRICE_SUBSCRIBED}` : `$${AIPHOTOS_BASIC_PRICE}`}
              </p>
            </div>
            <ul className="text-sm text-black-600 space-y-0.5 list-disc pl-4">
              <li>64 Photos</li>
              <li>8 Styles</li>
              <li>Ready in 3 hours</li>
            </ul>
          </div>

          <div
            onClick={() => handlePackageSelect(AIPhotosPlan.Plus)}
            className={`border-2 bg-white ${selectedPackage === AIPhotosPlan.Plus
              ? "border-brand-primary"
              : "border-transparent"
              } rounded-2xl p-4 flex-1 ml-1 cursor-pointer`}
          >
            <div className="flex justify-between items-center mb-1">
              <h3 className="font-semibold">Plus</h3>
              <p className="font-semibold">
                {isSubscribed ? `$${AIPHOTOS_PLUS_PRICE_SUBSCRIBED}` : `$${AIPHOTOS_PLUS_PRICE}`}
              </p>
            </div>
            <div className="text-brand-primary text-xxs mb-1 font-semibold">
              MOST POPULAR 🔥
            </div>
            <ul className="text-sm text-black-600 space-y-0.5 list-disc pl-4">
              <li>120 Photos</li>
              <li>15 Styles</li>
              <li>Ready in 3 hours</li>
            </ul>
          </div>
        </div>

        <button
          disabled={!selectedPackage || filesToUpload.length === 0}
          onClick={handleContinueClick}
          className={`mt-8 flex items-center justify-center w-full py-3 rounded-md font-semibold bg-brand-primary text-white ${filesToUpload.length === 0 ? "opacity-50 cursor-not-allowed" : ""
            }`}
        >
          Get your Profile Photos
        </button>

        {/* {!isSubscribed && (
          <div className="w-full text-red-500 py-3 font-semibold text-center">
            Get Premium, save 50% on AI Photos
          </div>
        )} */}
      </div>
    </div>
  );
};
