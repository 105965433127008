import React, { useEffect, useRef, useState } from 'react';
import { useAIPhotosStore } from '../../stores/aiphotos';
import { useWizardStore } from '../../stores/wizard';

type Props = {
  onContinuePress: VoidFunction;
};

export const UserEmailStep = ({ onContinuePress }: Props) => {
  const { aiPhotosEmail, setAIPhotosEmail } = useAIPhotosStore();
  const { email } = useWizardStore();
  const userEmailRef = useRef(aiPhotosEmail || email);
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    setDisabled(!isValidEmail(userEmailRef.current));
  }, []);

  const isValidEmail = (email: string) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    userEmailRef.current = e.target.value;
    setDisabled(!isValidEmail(userEmailRef.current));
  };

  const handleNextPress = () => {
    setAIPhotosEmail(userEmailRef.current);
    onContinuePress();
  };

  return (
    <div className="flex flex-col h-full max-w-md mx-auto">
      <div className="flex-grow overflow-y-auto">
        <h1 className="text-2xl font-bold mb-4">
          Enter Your Email
        </h1>
        <div className="space-y-4 mb-8">
          <div>
            <h4 className="font-medium text-sm mb-2">Email where to send your photos</h4>
            <input
              type="email"
              defaultValue={userEmailRef.current}
              onChange={handleEmailChange}
              placeholder="name@example.com"
              className="w-full px-4 py-2 border-2 border-gray-300 rounded-md focus:outline-none focus:border-black"
            />
          </div>
        </div>
        <button
          onClick={handleNextPress}
          disabled={disabled}
          className={`w-full py-3 rounded-md font-semibold ${!disabled
            ? "bg-brand-primary text-white"
            : "bg-gray-300 text-gray-500 cursor-not-allowed"
            }`}
        >
          Continue
        </button>
      </div>
    </div>
  );
};
