import React, { useState } from "react";
import { FaChevronLeft } from "react-icons/fa";
import { AIPhotoDetailView } from "./AIPhotoDetailView";
import { useDownloadImages } from "./useDownloadImages";

type Props = {
  imageUrls: string[];
  onBackClick: () => void;
}

export const AIPhotosBatchDetail = ({ imageUrls, onBackClick }: Props) => {
  const [selectedImage, setSelectedImage] = useState<{ url: string; index: number } | null>(null);

  const { downloadAllImages } = useDownloadImages()

  const handleImageBackClick = () => {
    setSelectedImage(null);
  };

  const handleImageClick = (imageUrl: string, index: number) => {
    setSelectedImage({ url: imageUrl, index });
  };

  return (
    <div className="flex flex-col h-full max-w-lg">
      {selectedImage ? (
        <AIPhotoDetailView
          imageUrl={selectedImage.url}
          imageIndex={selectedImage.index}
          totalImages={imageUrls.length}
          onBackClick={handleImageBackClick}
        />
      ) : (
        <>
          <div className="flex justify-between items-center py-4">
            <div className="flex items-center">
              <button onClick={onBackClick} className="text-gray-400">
                <FaChevronLeft size={24} />
              </button>
              {/* <h1 className="text-lg font-semibold ml-2">{photoName}</h1> */}
            </div>
            <button
              className="text-red-500 text-sm font-semibold"
              onClick={() => downloadAllImages(imageUrls)}
            >
              Download All
            </button>
          </div>

          <div className="flex-1 p-1">
            <div className="grid grid-cols-2 gap-4">
              {imageUrls.map((imageUrl, index) => (
                <img
                  key={index}
                  onClick={() => handleImageClick(imageUrl, index)}
                  src={imageUrl}
                  alt={`Generated Photo for ${imageUrl}`}
                  className="w-full h-full object-cover rounded-xl"
                />
              ))}
            </div>
          </div>
        </>
      )}
    </div>
  );
};